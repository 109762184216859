// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { EnvironmentsInterface } from "../app";
import { PackageJson } from "./package";

const pkg: PackageJson = require("../../../../package.json");

export const environment: EnvironmentsInterface = {
  production: true,
  testbed: true,
  hmr: false,
  apiUrlPart: "/api",
  appUrl: "",
  isBrowserEnv: true,
  centrifugoServerUrl: "wss://centrifugoqa.adex.ai:8000/connection/websocket",
  googleClientId:
    "953945332786-61asq8sf5pb392qdcutdsh0704iiqe9a.apps.googleusercontent.com",
  firebase: {
    apiKey: "AIzaSyA8YIBra6WbvO55ik2L-OX3lm7xuuQAoBw",
    authDomain: "itls-cloud.firebaseapp.com",
    databaseURL: "https://itls-cloud.firebaseio.com",
    projectId: "itls-cloud",
    storageBucket: "itls-cloud.appspot.com",
    messagingSenderId: "67511115823",
    appId: "1:67511115823:web:4028a30b1a655ee4",
  },
  googleDrive: {
    appId: "celtic-bazaar-385017",
    apiKey: "AIzaSyBFRnDAJopBLZMBKqf0_WlRXZUC7qa-0vY",
    clientId:
      "953945332786-3fn9dvfi4gn5jdcm8v451ft55aq8u0j8.apps.googleusercontent.com",
  },
  datadog: {
    applicationId: "dbbdce78-b381-4a36-994f-76549554533d",
    clientToken: "puba15547f5c90b589e0cb4bb852b4d3b87",
    site: "datadoghq.com",
    service: "adex-ui",
    env: "qa",
    version: "qa_" + pkg.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
    allowedTracingUrls: ["https://qa.adex.ai/api"],
    traceSampleRate: 100,
  },
};
